import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    init()
  }
}

function init() {
    manageContactAndProject()


    function manageContactAndProject() {
        let customer_id = $("#task_customer_id option:selected").data('customer');
        $("#task_project_id option").hide();
        $("[data-customer=" + customer_id + "]").show();

        $("#task_customer_contact_id option").hide();
        $("[data-customer-contact=" + customer_id + "]").show();

        $(".chosen-select").trigger("chosen:updated");


        $("#task_customer_id").change(function () {
            updateCustomerContactSelect();
            updateProjectSelect();
        });
    }

    function updateCustomerContactSelect() {
        $("#task_customer_contact_id option").prop('disabled', true);
        $("#task_customer_contact_id  option:selected").prop("selected", false);
        $(".chosen-select").trigger("chosen:updated");
        let customer_id = $("#task_customer_id option:selected").data('customer');
        $("#task_customer_contact_id option").hide();
        $("#task_customer_contact_id [data-customer-contact=" + customer_id + "]").show();
        $("#task_customer_contact_id [data-customer-contact=" + customer_id + "]").prop('disabled', false);
        $("#task_customer_contact_id [data-customer-contact=" + customer_id + "][data-default='true']").prop('selected', true);
        $(".chosen-select").trigger("chosen:updated");
    }

    function updateProjectSelect() {
        $("#task_project_id option").prop('disabled', true);
        $("#task_project_id  option:selected").prop("selected", false);
        $(".chosen-select").trigger("chosen:updated");
        let customer_id = $("#task_customer_id option:selected").data('customer');
        $("#task_project_id option").hide();
        $("#task_project_id [data-customer=" + customer_id + "]").show();
        $("#task_project_id [data-customer=" + customer_id + "]").prop('disabled', false);
        $(".chosen-select").trigger("chosen:updated");
    }
}
