/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
const images = require.context('../images', true)
require("trix")
require("@rails/actiontext")
import "core-js/stable"
import "regenerator-runtime/runtime"
import Rails from '@rails/ujs';
Rails.start();
require('jquery')
import '@popperjs/core'
import 'popper.js'
import * as bootstrap from 'bootstrap'
import Highcharts from 'highcharts'
import "@hotwired/turbo-rails"
import 'highcharts/modules/annotations'
import 'highcharts/css/highcharts.css'
window.Highcharts = Highcharts
require('../src/modal')
require('../src/safe_mode')
import "@fortawesome/fontawesome-free/js/all"
import 'chosen-js'
import 'chosen-js/chosen.css'
import 'fullcalendar'
import 'fullcalendar/dist/fullcalendar.css'
import 'fullcalendar/dist/locale/fr'
require("@nathanvda/cocoon")
require('../src/vendor/best_in_place')
let moment = require('moment')
moment().format();
require("moment/locale/fr")
require("tempusdominus-bootstrap-4")
import 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/l10n/fr'
require('../src/vendor/bs-typeahead.js')
import Sortable from 'sortablejs'
window.Sortable = Sortable;
window.bootstrap = require('bootstrap');
import Tagify from '@yaireo/tagify'
window.Tagify = Tagify;
import "controllers";
require('../src/hierarchy-select.min')
import Split from 'split.js'
window.Split = Split;
window.Cookies = require('js-cookie')