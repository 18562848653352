import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        init()
    }
}

function init() {


    $('.datepicker').flatpickr(
        {
            dateFormat: "d.m.Y",
            enableTime: false,
            locale: 'fr',
            allowInput: true,
        }
    );

    $('.datetimepicker').flatpickr(
        {
            dateFormat: "d.m.Y H:i",
            enableTime: true,
            locale: 'fr',
            defaultHour: new Date().getHours(),
            defaultMinute: new Date().getMinutes(),
            allowInput: true
        }
    );

    $('.chosen-container').remove();
    $('.chosen-select').chosen({
        allow_single_deselect: true,
        no_results_text: 'Pas de résultat',
        width: '100%'
    });

    $('.best_in_place').best_in_place()


}


