load_modal = function (url, options) {
    let is_image, modal, modalBody, modalDialog, modalHeader, modalTitle, remote_content, size, target_modal, title;
    if (options == null) {
        options = {};
    }
    target_modal = options['target'] || '#modal';
    title = options['title'] || 'Undefined';
    remote_content = url;
    is_image = /\.(gif|jpg|jpeg|tiff|png)(\?\d*)?$/i.test(remote_content);
    modal = document.getElementById('modal');
    modalHeader = $(target_modal + ' .modal-header');
    modalTitle = $(target_modal + ' .modal-title');
    modalBody = $(target_modal + ' .modal-body');
    modalDialog = $(target_modal + ' .modal-dialog');
    size = options['size'] || 'md';
    modalDialog.removeClass('modal-sm modal-md modal-lg');
    modalDialog.addClass('modal-' + size);
    if (options['centered']) {
        modalDialog.addClass('modal-dialog-centered');
    }
    modalBody.html('<div class="text-center">Chargement...</div>');
    modalBody.html('')
    var myModal = new bootstrap.Modal(modal, options)
    var loadModalFn = loadModalfn("loadModal: ");

    function loadModalfn(t) {
        var x = function () {
            modalTitle.html(title);
            modalBody.html('')
            if (title) {
                modalTitle.hide();
            }
            if (is_image) {
                return modalBody.html('<img src="' + remote_content + '" class="img-fluid mx-auto">');
            } else {
                return modalBody.load(remote_content);
            }
        }
        return x
    }
    modal.addEventListener('show.bs.modal', loadModalFn, false)
    myModal.show()
    modal.removeEventListener('show.bs.modal', loadModalFn, false)
};

event_modal = function () {
    return $('a[data-toggle="modal"]').on('click', function (e) {
        var options, url;
        url = e.currentTarget.href;
        options = {
            target: $(e.currentTarget).data('bs-target') || '#modal',
            title: e.currentTarget.title,
            size: $(e.currentTarget).data('size') || 'md',
            centered: $(e.currentTarget).data('centered') || false
        };
        e.preventDefault();
        return load_modal(url, options);
    });
};
document.addEventListener('turbo:load', function () {
    $('#modal').on('shown.bs.modal', function () {
        $('.datepicker').flatpickr(
            {
                dateFormat: "d.m.Y",
                enableTime: false,
                locale: 'fr',
                allowInput: true
            }
        );
        $('.chosen-select').chosen({
            allow_single_deselect: true,
            no_results_text: 'No results matched',
            width: '200px'
        });
        $('.datetimepicker').flatpickr(
            {
                dateFormat: "Y/m/d H:i",
                enableTime: true,
                locale: 'fr',
                defaultHour: new Date().getHours(),
                defaultMinute: new Date().getMinutes()
            });
        $('.best_in_place').best_in_place();
    });
    event_modal();
});
