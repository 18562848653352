import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        this.element.querySelectorAll('img').forEach(function(img) {
            let wrapper = document.createElement('a');
            wrapper.setAttribute('href', img.src);
            wrapper.setAttribute('target', "_blank");
            wrapper.appendChild(img.cloneNode(true));
            img.parentNode.replaceChild(wrapper, img);
        })
    }
}