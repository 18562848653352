import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    init()
  }
}

function init() {
  $('.datepicker').flatpickr(
      {
        dateFormat: "d.m.Y",
        enableTime: false,
        locale: 'fr',
      }
  );

  $('.datetimepicker').flatpickr(
      {
        dateFormat: "Y/m/d H:i",
        enableTime: true,
        locale: 'fr',
        defaultHour: new Date().getHours(),
        defaultMinute: new Date().getMinutes()
      }
  );

  $('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'Pas de résultat',
    width: '100%'
  });

  $('.best_in_place').best_in_place()
}
