import {Application, Controller} from "stimulus"
import Tribute from 'tributejs'
import Trix from 'trix'

export default class extends Controller {
    static targets = ['field']

    connect() {
        this.editor = this.fieldTarget.editor
        this.initializeTribute()
    }

    disconnect() {
        this.tribute.detache(this.fieldTarget)
    }

    initializeTribute() {
      this.tribute = new Tribute({
          trigger: '#',
          allowSpaces: true,
          lookup: 'title',
          values: this.fetchTasks,
          menuItemLimit: 15,
          menuShowMinLength: 1
      })
        this.tribute.attach(this.fieldTarget)
        this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
        this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
    }

    fetchTasks(text, callback){
        fetch(`/mentions.json?query=${text}`)
            .then(response => response.json())
            .then(tasks => callback(tasks))
            .catch(error => callback([]))
    }

    replaced(e) {
       let mention = e.detail.item.original
        let attachment = new Trix.Attachment({
            sgid: mention.sgid,
            content: mention.content
        })
        this.editor.insertAttachment(attachment)
        this.editor.insertString(" ")
    }

    _pasteHtml(html, startPos, endPos) {
        let position = this.editor.getPosition()
        this.editor.setSelectedRange([position - endPos + startPos, position + 1]);
        this.editor.deleteInDirection("backward")
    }
}