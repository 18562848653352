safeMode = function (form, unsavedstate = false) {
    var activateForm, unsavedChanges;
    unsavedChanges = unsavedstate;

    activateForm = function () {
        unsavedChanges = true;
        return form.find(':submit').removeAttr('disabled').removeClass('btn-secondary').addClass('btn-success');
    };

    if (unsavedChanges) {
        activateForm()
    }

    $(function () {
        form.find('input, select').change(activateForm);
        form.find('trix-editor').keyup(activateForm);
        form.find('input, textarea').keyup(activateForm);
        return form.submit(function () {
            return $(window).unbind('beforeunload');
        });
    });

    return $(window).bind('beforeunload', function () {
        if (unsavedChanges) {
            return 'You have not saved your changes.';
        }
    });
};